@import url("https://fonts.googleapis.com/css?family=Lato:400,900");

body {
  overflow-x: hidden;
}

.nav-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav-bullets-div {
  display: flex;
  justify-content: center;
  height: 150px;
}
.nav-ul-container-left {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  width: 25%;
  margin-left: 3%;
}
.nav-ul-container-right {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  width: 25%;
  margin-right: 3%;
}

.joshs-name-div {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-content: center;
  align-items: center;
  width: 45%;
  margin-right: 5%;
  margin-left: 5%;
}
.josh-name-big {
  color: #111;
  font-size: 47px;
  font-family: 'Rubik Mono One', sans-serif;
  font-weight: 500;
  text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
}

.nav-li-item {
  list-style: none;
  margin-left: 8%;
  margin-right: 8%;
}

.nav-li-item-reel {
  list-style: none;
  margin-left: 7%;
  margin-right: 8%;
}

.nav-li-item-media {
  list-style: none;
  margin-left: 8%;
  margin-right: 6%;
}

.nav-li-link {
  text-decoration: none;
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.6;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  z-index: 0;
  cursor: pointer;
  outline: none;
  list-style: none
}

.link-1 {
  list-style: none
}


#nav-li-link-title {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-content: center;
  align-items: center;
  text-decoration: none;
  width: 100%;
}

.u-shadow {
  text-shadow: 4px 4px 0 #6fd1bd;
  transition: text-shadow 0.2s ease-in-out;
}
.josh-name-big:hover .u-shadow {
  text-shadow: -4px -4px 0 salmon;
}

.nav-flex {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center; 
}

.link-1 .nav-li-link:before { left: 0; }
.link-1 .nav-li-link:after { right: 0; }

.link-1 .nav-li-link:hover:before, .link-1 .nav-li-link:hover:after {
  width: 50%;
}

.link-1 .nav-li-link:before, .link-1 .nav-li-link:after {
  -webkit-transition: all 0.4s ease;
          transition: all 0.4s ease;
} */


.nav-ul-container .nav-li-item {
  transition: background-position-x 0.9s linear;
  text-align: center;
}

.nav-li-link {
  transition: all 0.65s
}
.nav-li-link:hover {
  color: #07c9a2;
  animation: line 7s;
}

@keyframes line {
  0% {
    border-bottom: 2px solid #07c9a2;
  }
}

/* hamburger menu */
.ham-div {
  text-align: center;
}
#label {
  line-height: 70px;
  font-size: 30px;
  display: none;
  width: 30px;
  margin: 0 auto;
}
#toggle {
  display: none;
}
.hidden-nav-container {
  display: none;
}


@media only screen and (max-width: 955px) {
  /* Large Desktop */
  .nav-ul-container-left {
    display: none !important;
  }
  .nav-ul-container-right {
    display: none !important;
  }

  #label {
    display: block;
    cursor: pointer;
  }

  .hidden-nav-container {
    display: none;
    text-align: center;
    width: 100%;
  }
  
  .hidden-nav-bullets-div {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-content: center;
    align-items: center;
  }

  .hidden-nav-ul-container-left {
    padding: 0;
    margin: 0 3% 4% 0;
  }

  .hidden-nav-ul-container-right {
    padding: 0;
    margin: 0 0 4% 3%;
  }

  .hidden-nav-li-item {
    list-style: none;
    margin-top: 18px;
    box-shadow: 6px 7px 0px -1px #70d1bd;
    -webkit-box-shadow: 6px 7px 0px -1px #70d1bd;
    -moz-box-shadow: 6px 7px 0px -1px #70d1bd;
    border-bottom: 1px solid rgba(0,0,0,0.55);
    border-right: 1px solid rgba(0,0,0,0.55);
  }

  .hidden-nav-li-link {
    text-decoration: none;
    color: black;
    font-family: 'Cutive Mono', monospace;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.6;
    position: relative;
    z-index: 0;
    cursor: pointer;
    outline: none;
    list-style: none;
  }

  #toggle[type=checkbox]:checked + .hidden-nav-container {
    display: block;
    text-align: center;
  }

  .home-page-main-container {
    margin-top: 1%;
  }

}

.hidden-nav-li-item:hover {
  transition: 200ms;
  -webkit-box-shadow: 5px 5px 0px -1px rgba(234,92,90,1);
  -moz-box-shadow: 5px 5px 0px -1px rgba(234,92,90,1);
  box-shadow: 5px 5px 0px -1px rgba(234,92,90,1);
}