@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);
@import url(https://fonts.googleapis.com/css?family=Lato:400,900);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

  
.home-page-main-container {
  height: 1300px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

}

.home-page-content-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('https://i.imgur.com/jdMcQXW.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 47% 50%;
  background-size: 100% 100%;
  padding-bottom: 4%;
}

.homepage-img-of-josh {
  height: 465px;
  border-radius: 4px;
  box-shadow: 6px 6px 52px -1px rgb(113,209,189);
  z-index: 4;
}

.homepage-alternate-background-color {
  /* background-image: url('https://i.imgur.com/AH09luV.png'); */
  margin: 0 auto;
  margin-top: 15%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 6%;
}

.h1-says-actor {
  color:rgb(122,250,227);
}

.video-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 30px;
  margin: 0 auto;
  margin-bottom: 4%;
  border-bottom: 2px solid black;
}

.featured-WWE-video {
  width: 800px;
  height: 555px;
  box-shadow: 0px 0px 18px -5px rgba(0,0,0,0.75);
}

.featured-WWE-video-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin: 0 auto;
  margin-bottom: 4%;
  text-align: center;
  width: 65%;
  margin-top: 2%;
}



#arrow-link {
  text-decoration: none;
  margin: 0 auto;
  margin-top: -2.5%;
}

.arrow {
  height: 37px;
  -webkit-animation: shake-vertical 18s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
  animation: shake-vertical 18s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}



@-webkit-keyframes shake-vertical {
  0%,
  100% {
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateY(-3px);
  }
  20%,
  40%,
  60% {
    transform: translateY(3px);
  }
  80% {
    transform: translateY(3px);
  }
  90% {
    transform: translateY(-3px);
  }
}
@keyframes shake-vertical {
  0%,
  100% {
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateY(-3px);
  }
  20%,
  40%,
  60% {
    transform: translateY(3px);
  }
  80% {
    transform: translateY(3px);
  }
  90% {
        transform: translateY(-3px);
  }
}





.reel-main-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20%;
  width: 100%;
  margin: 0 auto;
  height: 670px;
}

.reel-content-container-div {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%; 
  height: 540px;
  width: 80%;
  margin: 0 auto;
  border-radius: 5px;
}

.reel-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 30px;
  margin: 0 auto;
  margin-bottom: 5%; 
}

.reel-video {
  box-shadow: 0px 0px 13px -1px rgba(0,0,0,0.66);
}

.credits-main-container {
  height: 910px;
  width: 100%;
}

.credits-top-part-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 3%;
}

.credits-main-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 37px;
  margin-top: 5%;
  margin-bottom: 3%;
  color: black;
  font-weight: 800;
  width: 100%;
  text-align: center;
}

.credits-links-ul {
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 1%;
}
.credits-link-li {
  margin-left: 0%;
  margin-right: 5%;
  padding: .5% 1% .5% 1%;
  border-radius: 30px;
  margin-top: 3%;
}
.credits-link {
  text-decoration: none;
  color: black;
  font-size: 21px;
  font-weight: 250;
  color: #000000;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
}
.link {
	position: relative;
	z-index: 1;
	display: inline-flex;
	
	padding-left: 10px;
	padding-bottom: 5px;
	padding-right: 10px;
}
.link::before{
	content: "";
	width: 100%;
	height: 100%;
	background-image: linear-gradient(to top, rgba(113,209,189,.8) 25%, rgba(0, 0, 0, 0) 40%);
	
	position: absolute;
	left: 0;
	bottom: 2px;
	z-index: -1;
	
	will-change: width;
	transform: rotate(-1deg);
	transform-origin: left bottom
}
.link:hover::before{
	width: 0;
}
.link::before{
	transition: width .1s ease-out;
}
.link:hover::before{
	transition-duration: .15s;
}

.credits-vitals-ul {
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 1%;
}
.credits-vitals-li {
  margin-left: 1%;
  margin-right: 1%;
  font-family: 'Cutive Mono', monospace;
}
.credits-vitals-span {
  font-weight: 800;
  font-size: 19px;
}

.member-equity-text {
  font-family: 'Cutive Mono', monospace;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-flow: row wrap;
  width: 50%;
  margin-bottom: 5%;
  text-align: center;
}

.members-equity-logo {
  height: 25px;
  padding-left: 2%;
}

.credits-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.theatre-container {
  padding: 2%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
}

.tv-container {
  padding: 2%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
}

.podcasts-container {
  padding: 2%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
}

.training-container {
  padding: 2%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
}
.special-skills-container {
  padding: 2%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin-bottom: 5%;
}

.theater-section-title {
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid rgb(167, 167, 167);  
  margin-bottom: 20%;
  font-size: 30px;
  text-align: center;
}
.special-skills-section-title {
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid rgb(167, 167, 167);  
  margin-bottom: 20%;
  font-size: 30px;
  text-align: center;
}
.tv-section-title {
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid rgb(167, 167, 167);  
  font-size: 30px;
  text-align: center;
}
.podcasts-section-title {
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid rgb(167, 167, 167);  
  margin-bottom: 10%;
  font-size: 30px;
  text-align: center;
}
.training-section-title {
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid rgb(167, 167, 167);  
  margin-bottom: 20%;
  font-size: 30px;
  text-align: center;
}
.special-skills-section-title {
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid rgb(167, 167, 167);  
  margin-bottom: 10%;
  font-size: 30px;
  text-align: center;
}

.theater-info-part {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 40%;
  border-bottom: 1px dashed rgb(196, 196, 196);
  padding-bottom: 3%;
  margin-top: 3%;
  text-align: center;
}
.training-info-part {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 40%;
  border-bottom: 1px dashed rgb(196, 196, 196);
  padding-bottom: 3%;
  margin-top: 3%;
  text-align: center;
}
.special-skills-info-part {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 40%;
  border-bottom: 1px dashed rgb(196, 196, 196);
  padding-bottom: 3%;
  margin-top: 3%;
  text-align: center;
}

.special-skills-info-part-last {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 40%;
  padding-bottom: 3%;
  margin-top: 3%;
  text-align: center;
}

.theater-info-part-last {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 40%;
  padding-bottom: 3%;
  margin-top: 3%;
  text-align: center;
}
.training-info-part-last {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 40%;
  padding-bottom: 3%;
  margin-top: 3%;
  text-align: center;
}

.theater-play-and-character {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.play-name {
  font-family: "Montserrat", sans-serif;  
  font-weight: 800;
  font-size: 17px;
  text-align: center;
}
.special-skills-name {
  font-family: "Montserrat", sans-serif;  
  font-weight: 800;
  font-size: 17px;
  text-align: center;
}

.play-character {
  font-family: "Montserrat", sans-serif;  
  font-size: 17px;
  font-weight: 400;
  margin-top: -1%;
  text-align: center;
}
.theater-name {
  margin-top: -1%;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;  
  font-weight: 200;
  font-style: italic;
  text-align: center;
}

.podcast-title {
  font-family: "Montserrat", sans-serif;  
  font-weight: 800;
  font-size: 17px;
  text-align: center;
}
.training-title {
  font-family: "Montserrat", sans-serif;  
  font-weight: 800;
  font-size: 17px;
  text-align: center;
  text-align: center;
}
.training-title-last {
  font-family: "Montserrat", sans-serif;  
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  text-align: center;
}

.podcast-character {
  font-family: "Montserrat", sans-serif;  
  font-size: 17px;
  font-weight: 400;
  margin-top: -1%;
  text-align: center;
}
.podcast-name {
  margin-top: -1%;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;  
  font-weight: 200;
  font-style: italic;
  text-align: center;
}
.podcast-episode {
  margin-top: -1%;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;  
  font-weight: 200;
  font-style: italic;
  text-align: center;
}

.special-skills-specifics {
  margin-top: -1%;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;  
  font-weight: 200;
  font-style: italic;
  text-align: center;
}

.special-skills-singular {
  margin-top: -1%;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;  
  font-weight: 600;
  text-align: center;
}

#master-class {
  font-weight: 800;
}



#link {
  text-decoration: none;
  margin-top: 6%;
  margin: 0 auto;
}

.arrow {
  -webkit-animation: shake-vertical 18s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
  animation: shake-vertical 18s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

@-webkit-keyframes shake-vertical {
  0%,
  100% {
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateY(-3px);
  }
  20%,
  40%,
  60% {
    transform: translateY(3px);
  }
  80% {
    transform: translateY(3px);
  }
  90% {
    transform: translateY(-3px);
  }
}
@keyframes shake-vertical {
  0%,
  100% {
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateY(-3px);
  }
  20%,
  40%,
  60% {
    transform: translateY(3px);
  }
  80% {
    transform: translateY(3px);
  }
  90% {
        transform: translateY(-3px);
  }
}


#scroll-your-role-id {
  background-color: #71d1bd;
  font-family: 'Cutive Mono', monospace;
  border-radius: 100%; 
  width: 45px;
  padding: 8px 5px 10px 3px;
  font-size: 20px;
  font-weight: 200;
  border: 1px solid black;
}

.images-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.images-content-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
}

.images-tab-imgs-of-josh {
  height: 300px;
  margin: 0% 3% 6% 3%;
  box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
  transition: .3s ease-in-out;
}

.headshots-header-text {
  font-family: 'Montserrat', sans-serif;
  color: black;
  font-weight: 800;
  font-size: 30px;
  width: 15%;
  text-align: center;
  padding-top: 1%;
  margin-bottom: 5%;
  margin-top: 5%;
}

.images-tab-imgs-of-josh:hover {
  transform: scale(2);
}

.headshots-giving-credit-text {
  text-decoration: none;
  color: black;
  font-family: 'Cutive Mono', monospace;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.6;
  
}

.headshots-credit-div {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 90%;
  margin-bottom: 4%;
}

.headshots-giving-credit-text {
  transform-origin: center; 
  text-align: center;
}

.headshots-giving-credit-text .link-to-headshot-guy {
  transition: all .3s linear;
  display: inline;
  font-size: 18px;
  line-height: 45px;
  color: black;
  text-decoration: none;
  text-transform: uppercase;
  padding-bottom: 5px;
  background: linear-gradient(to right, #00d1b1 0%, #278fc6 98%);
  background-size: 0px 2px;
  background-repeat: no-repeat;
  background-position: center 85%;
  text-shadow: 0;
}

.headshots-giving-credit-text .link-to-headshot-guy:hover {
  background-size: 100% 2px;
  text-shadow: 0 2.5px 4px rgba(0, 0, 0, 0.5);
}
.about-main-container {
  margin-bottom: 20%;
  width: 100%;
  margin: 0 auto;
}

.about-title-text {
  color: #71d1bd;
  font-family: 'Montserrat', sans-serif;
  width: 20%;
  margin: 0 auto;
  text-align: center;
  font-weight: 900;
  font-size: 35px;
  margin-bottom: 6%;
  padding-top: 3%;
  display: flex;
  justify-content: center;
}

.about-text-main-container {
  width: 100%;
  margin-top: -1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
} 

.about-img-container {
  margin-top: 1%;
}

.about-text-container {
  margin-top: 2%;
  width: 60%;
  margin-bottom: 5%;
}

.about-me-text {
  padding-top: 3%;
  text-align: center;
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 1.4em;
}

.about-city-country-img {
  width: 100%;
  max-width: 600px;
  height: auto;
}
.media-main-container {
  margin: 0 auto;
}

.media-content-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 95%;
  padding-top: 8%;
  margin-bottom: 10%;
  margin: 0 auto;
}

.media-tab-imgs-of-josh {
  height: 300px;
  margin: 0% 3% 10% 3%;
  box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
  transition: .3s ease-in-out;
}

.media-tab-imgs-of-josh-small {
  height: 300px;
  margin: 0% 3% 10% 3%;
  box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
  transition: .3s ease-in-out;
}

.media-header-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 30px;
  width: 8.5%;
  margin: 0 auto;
  padding-top: 5%;
  text-align: center;
  color: black;
}

.media-tab-imgs-of-josh:hover {
  transform: scale(1.2);
}
.media-tab-imgs-of-josh-small:hover {
  transform: scale(1.7);
}

.featured-WWE-video-media {
  width: 800px;
  height: 555px;
  box-shadow: 0px 0px 18px -5px rgba(0,0,0,0.75);
  margin-bottom: 5%;
}

#contactform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

#name-input {
  -webkit-appearance: none;
  box-shadow: 6px 7px 0px -1px #70d1bd;
  -webkit-box-shadow: 6px 7px 0px -1px #70d1bd;
  -moz-box-shadow: 6px 7px 0px -1px #70d1bd;
  border: 1px solid rgba(0,0,0,0.55);
  font-size: 13px;
}

#email-input {
  -webkit-appearance: none;
  box-shadow: 6px 7px 0px -1px #70d1bd;
  -webkit-box-shadow: 6px 7px 0px -1px #70d1bd;
  -moz-box-shadow: 6px 7px 0px -1px #70d1bd;
  border: 1px solid rgba(0,0,0,0.55);
  font-size: 13px;
}

#subject-input {
  -webkit-appearance: none;
  box-shadow: 6px 7px 0px -1px #70d1bd;
  -webkit-box-shadow: 6px 7px 0px -1px #70d1bd;
  -moz-box-shadow: 6px 7px 0px -1px #70d1bd;
  border: 1px solid rgba(0,0,0,0.55);
  font-size: 13px;
}

#message-input {
  -webkit-appearance: none;
  box-shadow: 6px 7px 0px -1px #70d1bd;
  -webkit-box-shadow: 6px 7px 0px -1px #70d1bd;
  -moz-box-shadow: 6px 7px 0px -1px #70d1bd;
  border: 1px solid rgba(0,0,0,0.55);
  font-size: 13px;
}

#submit-button {
  -webkit-appearance: none;
  background-color:  #70d1bd;  
  color: rgb(234,92,90); 
  font-size: 20px;
  font-weight: 600;
  font-family: Cutive Mono, monospace;
  margin-top: 3%;
  box-shadow: 6px 6px 0px -1px rgb(234,92,90);
  -webkit-box-shadow: 6px 6px 0px -1px rgb(234,92,90);
  -moz-box-shadow: 6px 6px 0px -1px rgb(234,92,90);
  transition: 400ms;
}

#submit-button:hover {
  -webkit-appearance: none;
  box-shadow: -6px -6px 0px -1px rgb(234,92,90);
  -webkit-box-shadow: -6px -6px 0px -1px rgb(234,92,90);
  -moz-box-shadow: -6px -6px 0px -1px rgb(234,92,90);
  transition: 400ms;
  background-color:  #70d1bd;  
}

#submit-button:focus {
  outline: 0;
}
#message-input:focus {
  outline: 0;
}
#subject-input:focus {
  outline: 0;
}
#email-input:focus {
  outline: 0;
}
#name-input:focus {
  outline: 0;
}
input[type=text],input[type=email], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}

input[type=submit] {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

input[type=submit]:hover {
    background-color: #45a049;
}

.contact-main-container {
  margin-top: 4%;
  height: 650px;
}

.contact-form-container {
  width: 30%;
  margin: 0 auto;
}

.contact-title {
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  font-size: 35px;
  width: 13% !important;
  margin: 0 auto;
  text-align: center;
  font-weight: 800;
  margin-bottom: 3%;
}

.contact-description {
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
  text-align: center;
  margin-bottom: 2%;
  font-weight: 400;
  margin-top: 3%;
}

.social-container {
  width: 400px;
  margin: 0 auto;
  text-align: center;
  padding-top: 1%;
  margin-top: 1%;
}

.social-icons {
  padding: 0;
  list-style: none;
  margin: 1em;
}
.social-icons .li-social {
  display: inline-block;
  margin: 0.15em;
  position: relative;
  font-size: 1.2em;
}
.social-icons i {
  color: #fff;
  position: absolute;
  top: 15px;
  left: 18px;
  transition: all 265ms ease-out;
}
.social-icons .a-social {
  display: inline-block;
}
.social-icons .a-social:before {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  content: " ";
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: block;
  background: linear-gradient(45deg, rgb(0, 237, 245), #70d1bd );
  transition: all 265ms ease-out;
}
.social-icons .a-social:hover:before {
  transform: scale(0);
  transition: all 265ms ease-in;
}
.social-icons .a-social:hover i {
  transform: scale(2.1);
  -ms-transform: scale(2.1);
  -webkit-transform: scale(2.1);
  color: #70d1bd;
  background: -webkit-linear-gradient(45deg, #2dc7ff, #70d1bd );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 265ms ease-in;
}
.fa {
  width: 44%;
  padding-top: 1px;
}

.li-social {
  padding: 0 2% 0 2%;
}
body {
  overflow-x: hidden;
}

.nav-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav-bullets-div {
  display: flex;
  justify-content: center;
  height: 150px;
}
.nav-ul-container-left {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  width: 25%;
  margin-left: 3%;
}
.nav-ul-container-right {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  width: 25%;
  margin-right: 3%;
}

.joshs-name-div {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-content: center;
  align-items: center;
  width: 45%;
  margin-right: 5%;
  margin-left: 5%;
}
.josh-name-big {
  color: #111;
  font-size: 47px;
  font-family: 'Rubik Mono One', sans-serif;
  font-weight: 500;
  text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
}

.nav-li-item {
  list-style: none;
  margin-left: 8%;
  margin-right: 8%;
}

.nav-li-item-reel {
  list-style: none;
  margin-left: 7%;
  margin-right: 8%;
}

.nav-li-item-media {
  list-style: none;
  margin-left: 8%;
  margin-right: 6%;
}

.nav-li-link {
  text-decoration: none;
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.6;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  z-index: 0;
  cursor: pointer;
  outline: none;
  list-style: none
}

.link-1 {
  list-style: none
}


#nav-li-link-title {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-content: center;
  align-items: center;
  text-decoration: none;
  width: 100%;
}

.u-shadow {
  text-shadow: 4px 4px 0 #6fd1bd;
  transition: text-shadow 0.2s ease-in-out;
}
.josh-name-big:hover .u-shadow {
  text-shadow: -4px -4px 0 salmon;
}

.nav-flex {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center; 
}

.link-1 .nav-li-link:before { left: 0; }
.link-1 .nav-li-link:after { right: 0; }

.link-1 .nav-li-link:hover:before, .link-1 .nav-li-link:hover:after {
  width: 50%;
}

.link-1 .nav-li-link:before, .link-1 .nav-li-link:after {
  transition: all 0.4s ease;
} */


.nav-ul-container .nav-li-item {
  transition: background-position-x 0.9s linear;
  text-align: center;
}

.nav-li-link {
  transition: all 0.65s
}
.nav-li-link:hover {
  color: #07c9a2;
  -webkit-animation: line 7s;
          animation: line 7s;
}

@-webkit-keyframes line {
  0% {
    border-bottom: 2px solid #07c9a2;
  }
}

@keyframes line {
  0% {
    border-bottom: 2px solid #07c9a2;
  }
}

/* hamburger menu */
.ham-div {
  text-align: center;
}
#label {
  line-height: 70px;
  font-size: 30px;
  display: none;
  width: 30px;
  margin: 0 auto;
}
#toggle {
  display: none;
}
.hidden-nav-container {
  display: none;
}


@media only screen and (max-width: 955px) {
  /* Large Desktop */
  .nav-ul-container-left {
    display: none !important;
  }
  .nav-ul-container-right {
    display: none !important;
  }

  #label {
    display: block;
    cursor: pointer;
  }

  .hidden-nav-container {
    display: none;
    text-align: center;
    width: 100%;
  }
  
  .hidden-nav-bullets-div {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-content: center;
    align-items: center;
  }

  .hidden-nav-ul-container-left {
    padding: 0;
    margin: 0 3% 4% 0;
  }

  .hidden-nav-ul-container-right {
    padding: 0;
    margin: 0 0 4% 3%;
  }

  .hidden-nav-li-item {
    list-style: none;
    margin-top: 18px;
    box-shadow: 6px 7px 0px -1px #70d1bd;
    -webkit-box-shadow: 6px 7px 0px -1px #70d1bd;
    -moz-box-shadow: 6px 7px 0px -1px #70d1bd;
    border-bottom: 1px solid rgba(0,0,0,0.55);
    border-right: 1px solid rgba(0,0,0,0.55);
  }

  .hidden-nav-li-link {
    text-decoration: none;
    color: black;
    font-family: 'Cutive Mono', monospace;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.6;
    position: relative;
    z-index: 0;
    cursor: pointer;
    outline: none;
    list-style: none;
  }

  #toggle[type=checkbox]:checked + .hidden-nav-container {
    display: block;
    text-align: center;
  }

  .home-page-main-container {
    margin-top: 1%;
  }

}

.hidden-nav-li-item:hover {
  transition: 200ms;
  box-shadow: 5px 5px 0px -1px rgba(234,92,90,1);
}
/* ––––––––– Media Queries –––––––– */

@media only screen  and (min-width : 1800px) {
	.nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 7%;
	}  

  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 7%;
	}
	
  .nav-li-item {
    list-style: none;
    margin-left: 14%;
    margin-right: 14%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 13%;
  	margin-right: 14%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 14%;
  	margin-right: 12%;
	}


	.homepage-alternate-background-color {
		background-image: url('https://i.imgur.com/AH09luV.png');
		height: 550px;
		width: 55%;
		margin: 0 auto;
		margin-top: 10%;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-direction: column;
	}
	
	.home-page-main-container {
		height: 1400px;
		width: 100%;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
	}
	
	.homepage-img-of-josh {
		height: 600px;
		border-radius: 4px;
		box-shadow: 6px 6px 52px -1px rgb(113,209,189);
		z-index: 4;
		margin-bottom: .5%;
	}

	.nav-bullets-div {
		display: flex;
		justify-content: center;
		height: 170px;
	}

	.about-title-text {
		width: 11%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}
	
	.headshots-header-text {
		width: 10%;
	}

	.images-content-container {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		width: 70%;
	}

	.media-content-container {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		width: 80%;
		padding-top: 8%;
		margin-bottom: 10%;
		margin: 0 auto;
	}

	.media-header-text {
		color: #70d1bd;
		width: 8%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}
	
	.reel-content-container-div {
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-direction: column;
		background-image: url('https://i.imgur.com/AH09luV.png');
		height: 540px;
		width: 70%;
		margin: 0 auto;
		border-radius: 5px;
		margin-top: 1%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 15%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
	
}

@media only screen  and (min-width : 1665px) {
	
	.nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 7%;
	}  
    
  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 7%;
	}
  .nav-li-item {
    list-style: none;
    margin-left: 14%;
    margin-right: 14%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 13%;
  	margin-right: 14%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 14%;
  	margin-right: 12%;
	}

	.about-title-text {
		width: 12%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

	.headshots-header-text {
		width: 12%;
	}
	.images-content-container {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		width: 70%;
	}

	.media-header-text {
		color: black;
		width: 8%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}
		
	.reel-content-container-div {
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-direction: column;
		background-image: url('https://i.imgur.com/AH09luV.png');
		height: 540px;
		width: 70%;
		margin: 0 auto;
		border-radius: 5px;
		margin-top: 1%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 115%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
	
	
}


@media screen and (max-width: 1655px) {

  .nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
	}  
    
  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
	}
  .nav-li-item {
    list-style: none;
    margin-left: 12%;
    margin-right: 12%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 11%;
  	margin-right: 12%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 12%;
  	margin-right: 10%;
	}

	.about-title-text {
		width: 14%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}
	.headshots-header-text {
		width: 12%;
	}
	.images-content-container {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		width: 90%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 115%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

	
}

@media screen and (max-width: 1620px) {
  .nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
	}  
    
  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
	}
  .nav-li-item {
    list-style: none;
    margin-left: 12%;
    margin-right: 12%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 11%;
  	margin-right: 12%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 12%;
  	margin-right: 10%;
	}

	.headshots-header-text {
		width: 12%;
	}

		
	.media-header-text {
		color: black;
		width: 8%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

}

@media screen and (max-width: 1595px) {

	.nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
	}  
    
  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
	}
  .nav-li-item {
    list-style: none;
    margin-left: 12%;
    margin-right: 12%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 11%;
  	margin-right: 12%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 12%;
  	margin-right: 10%;
	}

	.about-title-text {
		width: 20%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

	.headshots-header-text {
		width: 13%;
	}
}

@media screen and (max-width: 1555px) {

  .nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
	}  
    
  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
	}
  .nav-li-item {
    list-style: none;
    margin-left: 12%;
    margin-right: 12%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 11%;
  	margin-right: 12%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 12%;
  	margin-right: 10%;
	}
	.headshots-header-text {
		width: 13%;
	}

}

@media screen and (max-width: 1540px) {

  .nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
	}  
    
  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
	}
  .nav-li-item {
    list-style: none;
    margin-left: 12%;
    margin-right: 12%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 11%;
  	margin-right: 12%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 12%;
  	margin-right: 10%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 115%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

}

@media screen and (max-width: 1478px) {

	.nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
	}  
    
  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
	}
  .nav-li-item {
    list-style: none;
    margin-left: 12%;
    margin-right: 12%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 11%;
  	margin-right: 12%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 12%;
  	margin-right: 10%;
	}

		
	.media-header-text {
		color: black;
		width: 9%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

}

@media screen and (max-width: 1444px) {

  .nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
	}  
    
  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
	}
  .nav-li-item {
    list-style: none;
    margin-left: 10%;
    margin-right: 10%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 9%;
  	margin-right: 10%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 10%;
  	margin-right: 8%;
	}

	.headshots-header-text {
		width: 14%;
	}
}



@media screen and (max-width: 1421px) {

  .nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
  }
    
  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
  }
    
  .nav-li-item {
    list-style: none;
    margin-left: 10%;
    margin-right: 10%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 9%;
  	margin-right: 10%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 10%;
  	margin-right: 8%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 115%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

}


@media screen and (max-width: 1375px) {

  .nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
	}

  .navl-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
	} 

  .nav-li-item {
    list-style: none;
    margin-left: 10%;
    margin-right: 10%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 9%;
  	margin-right: 10%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 10%;
  	margin-right: 8%;
	}

	.about-title-text {
		width: 20%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

}




@media screen and (max-width: 1330px) {

  .nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
  }

  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
  }

  .nav-li-item {
    list-style: none;
    margin-left: 8%;
    margin-right: 8%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 7%;
  	margin-right: 8%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 8%;
  	margin-right: 6%;
	}
	.headshots-header-text {
		width: 15%;
	}

	.media-header-text {
		color: black;
		width: 10%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}
}


@media screen and (max-width: 1266px) {

	#nav-li-link-title {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-content: center;
		align-items: center;
		text-decoration: none;
		width: 100%;
	}

	.joshs-name-div {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-content: center;
		align-items: center;
		width: 35%;
		margin-right: 5%;
		margin-left: 5%;
	}

	.josh-name-big {
		color: #111;
		font-size: 45px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
	}
	.joshs-name-div {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-content: center;
		align-items: center;
		width: 45%;
		margin-right: 5%;
		margin-left: 5%;
	}
	.headshots-header-text {
		width: 15%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 115%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

}


@media screen and (max-width: 1248px) {
	.josh-name-big {
		color: #111;
		font-size: 43px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
	}
	.joshs-name-div {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-content: center;
		align-items: center;
		width: 45%;
		margin-right: 5%;
		margin-left: 5%;
	}

	.about-title-text {
		width: 20%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}
	.headshots-header-text {
		width: 16%;
	}

	.reel-title {
		font-family: 'Cutive Mono', monospace;
		font-weight: 200;
		font-size: 32px;
		border-bottom: 1px solid black;
		margin: 0 auto;
		margin-bottom: 6%; 
	}
}


@media screen and (max-width: 1200px) {
  /* Large Desktop */
	.josh-name-big {
		color: #111;
		font-size: 43px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
	}
	.joshs-name-div {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-content: center;
		align-items: center;
		width: 45%;
		margin-right: 5%;
		margin-left: 5%;
	}
	
	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 115%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
}

@media screen and (max-width: 1155px) {
  /* Large Desktop */
	.josh-name-big {
		color: #111;
		font-size: 40px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
	}
	.joshs-name-div {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-content: center;
		align-items: center;
		width: 45%;
		margin-right: 5%;
		margin-left: 5%;
	}
	.about-title-text {
		width: 20%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

	.about-title-text {
		width: 20%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}
	.headshots-header-text {
		width: 17%;
	}
		
	.media-header-text {
		color: black;
		width: 11%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

	.theater-info-part {
		width: 45%;
	}
	.training-info-part {
		width: 45%;
	}
	.special-skills-info-part {
		width: 45%;
	}
	.theater-info-part-last {
		width: 45%;
	}
	.training-info-part-last {
		width: 45%;
	}
	.special-skills-info-part-last {
		width: 45%;
	}


	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 14.15%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

}


@media screen and (max-width: 1043px) {
  /* Large Desktop */
	.josh-name-big {
		color: #111;
		font-size: 43px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
	}
	.joshs-name-div {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-content: center;
		align-items: center;
		width: 45%;
		margin-right: 5%;
		margin-left: 5%;
	}

	.about-title-text {
		width: 20%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

	.headshots-header-text {
		width: 19%;
	}
		
	.media-header-text {
		color: black;
		width: 12%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

	.contact-form-container {
		width: 40%;
		margin: 0 auto;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 115%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
}

@media screen and (max-width: 975px) {
  /* Large Desktop */
	.homepage-img-of-josh {
		height: 400px;
		border-radius: 4px;
		/* padding-right: 20%; */
		box-shadow: 6px 6px 52px -1px rgb(113,209,189);
		z-index: 4;
		margin-bottom: .5%;
	}


	.theater-info-part {
		width: 50%;
	}
	.training-info-part {
		width: 50%;
	}
	.special-skills-info-part {
		width: 50%;
	}
	.theater-info-part-last {
		width: 50%;
	}
	.training-info-part-last {
		width: 50%;
	}
	.special-skills-info-part-last {
		width: 50%;
	}


	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 115%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
}

@media screen and (max-width: 954px) {
	.josh-name-big {
		color: #111;
		font-size: 42px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
	}
	.joshs-name-div {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-content: center;
		align-items: center;
		width: 100%;
		margin: 0;
	}
	.nav-bullets-div {
		display: flex;
		justify-content: center;
		height: 50px;
		margin-top: 5%;
	}
	#arrow-link {
		text-decoration: none;
		display: flex;
		justify-content: center;
		margin-top: 2%;
	}

	.about-text-container {
		margin-top: 2%;
		width: 70%;
	}
	.headshots-header-text {
		width: 21%;
	}
		
	.media-header-text {
		color: black;
		width: 14%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

	.home-page-main-container {
		margin-top: 2%;
	}


	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 115%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
}

@media screen and (max-width: 880px) {
	
	#player {
		width: 80% !important;
		height: 61% !important;
	}
	#player2 {
		width: 80% !important;
		height: 61% !important;
	}


	.about-title-text {
		width: 22%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}
	
	.headshots-header-text {
		width: 22%;
	}

	.reel-content-container-div {
		height: 480px;
		margin-top: 5%;
	}

	.theater-info-part {
		width: 60%;
	}
	.training-info-part {
		width: 60%;
	}
	.special-skills-info-part {
		width: 60%;
	}
	.theater-info-part-last {
		width: 60%;
	}
	.training-info-part-last {
		width: 60%;
	}
	.special-skills-info-part-last {
		width: 60%;
	}


	.contact-form-container {
		width: 50%;
		margin: 0 auto;
	}


	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 215%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

	.contact-description {
		color: black;
		font-family: 'Cutive Mono', monospace;
		font-size: 20px;
		text-align: center;
		margin: 0 auto;
		margin-bottom: 4%;
		width: 90%;
	}
}


@media screen and (max-width: 786px) {
  /* tablet */
	.homepage-img-of-josh {
		height: 350px;
		border-radius: 4px;
		/* padding-right: 20%; */
		box-shadow: 6px 6px 52px -1px rgb(113,209,189);
		z-index: 4;
		margin-bottom: .5%;
	}
	.about-title-text {
		width: 24%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}
	.about-text-container {
		margin-top: 2%;
		width: 75%;
	}

	.headshots-header-text {
		width: 24%;
	}

		
	.media-header-text {
		color: black;
		width: 16%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

	#player2 {
		width: 80% !important;
		height: 50% !important;
	}

	.reel-content-container-div {
		height: 480px;
		margin-top: 5%;
	}

	.credits-vitals-li {
		margin-left: 3%;
		margin-right: 3%;
		font-family: 'Cutive Mono', monospace;
		margin-top: 2%
	}

	.video-text {
		margin-bottom: 7%;
	}
	

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 215%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

	.images-tab-imgs-of-josh:hover {
		transform: scale(1.5);
	}

}


@media screen and (max-width: 747px) {
	#scroll-your-role-id {
		background-color: #71d1bd;
		font-family: 'Cutive Mono', monospace;
		border-radius: 100%;
		width: 50px;
		padding: 2px 13px 2px 0px;
		font-size: 20px;
		font-weight: 200;
		border: 1px solid black;
	}
}

@media screen and (max-width: 725px) {
	.members-equity-logo {
  	height: 25px;
		padding-left: 0%;
		margin-top: 2%;
		margin-bottom: 10%;
	}

	.theater-info-part {
		width: 70%;
	}
	.training-info-part {
		width: 70%;
	}
	.special-skills-info-part {
		width: 70%;
	}
	.theater-info-part-last {
		width: 70%;
	}
	.training-info-part-last {
		width: 70%;
	}
	.special-skills-info-part-last {
		width: 70%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 215%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
}


@media screen and (max-width: 709px) {
	.homepage-img-of-josh {
		height: 320px;
		border-radius: 4px;
		box-shadow: 6px 6px 52px -1px rgb(113,209,189);
		z-index: 4;
		margin-bottom: .5%;
	}

		.about-title-text {
		width: 26%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

	.headshots-header-text {
		width: 28%;
	}

	.josh-name-big {
		color: #111;
		font-size: 40px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
	}
}

@media screen and (max-width: 667px) {
  /* mobile */
	.about-title-text {
		width: 31%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

	.headshots-header-text {
		width: 31%;
		margin-bottom: 10%;
	}

	.media-header-text {
		color: black;
		width: 19%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

	#player2 {
		width: 80% !important;
		height: 40% !important;
	}

	#player {
		width: 80% !important;
		height: 60% !important;
	}

	.member-equity-text {
		font-family: 'Cutive Mono', monospace;
		font-size: 15px;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-flow: row wrap;
		width: 50%;
		margin-bottom: 5%;
		text-align: center;
		margin-top: 4%;
	}


	.theater-info-part {
		width: 83%;
	}
	.training-info-part {
		width: 83%;
	}
	.special-skills-info-part {
		width: 83%;
	}
	.theater-info-part-last {
		width: 83%;
	}
	.training-info-part-last {
		width: 83%;
	}
	.special-skills-info-part-last {
		width: 83%;
	}

	.homepage-img-of-josh {
		height: 280px;
	}

	.home-page-main-container {
		margin-top: 3%;
	}

	.homepage-alternate-background-color {
		background-image: url('https://i.imgur.com/AH09luV.png');
		height: 400px;
		width: 80%;
		margin: 0 auto;
		margin-top: 10%;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-direction: column;
	}

	.contact-form-container {
		width: 59%;
		margin: 0 auto;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 215%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
}

@media screen and (max-width: 535px) {
  /* mobile */
	.about-title-text {
		width: 34%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

	.headshots-header-text {
		width: 38%;
	}

	.media-tab-imgs-of-josh {
		height: 230px;
		margin: 0% 3% 10% 3%;
		box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
		transition: .3s ease-in-out;
	}
	
	.media-tab-imgs-of-josh-small {
		height: 250px;
		margin: 0% 3% 10% 3%;
		box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
		transition: .3s ease-in-out;
	}
	
	.media-header-text {
		color: black;
		width: 23%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

	.theater-info-part {
		width: 95%;
	}
	.training-info-part {
		width: 95%;
	}
	.special-skills-info-part {
		width: 95%;
	}
	.theater-info-part-last {
		width: 95%;
	}
	.training-info-part-last {
		width: 95%;
	}
	.special-skills-info-part-last {
		width: 95%;
	}

	.homepage-img-of-josh {
		height: 280px;
		margin-bottom: 5%;
	}

	.josh-name-big {
		color: #111;
		font-size: 38px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
	}

	.home-page-content-container {
		width: 100%;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-direction: column;
		background-image: none;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 47% 50%;
		background-size: 100% 100%;
		padding-bottom: 4%;
	}

	#player {
		width: 80% !important;
		height: 60% !important;
	}
	.homepage-alternate-background-color {
		background-image: url('https://i.imgur.com/AH09luV.png');
		height: 370px;
		width: 85%;
		margin: 0 auto;
		margin-top: 10%;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-direction: column;
	}

	.contact-form-container {
		width: 63%;
		margin: 0 auto;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 315%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}


}

@media screen and (max-width: 480px) {
  /* mobile */
	.about-title-text {
		font-family: 'Cutive Mono', monospace;
		width: 39%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}
	.about-me-text {
		border-left: 2px solid lightgray;
		border-right: 2px solid lightgray;
		padding-left: 5%;
		padding-right: 5%;
	}
	.about-text-container {
		margin-top: 2%;
		width: 80%;
	}

	.headshots-header-text {
		width: 42%;
	}

	.media-header-text {
		color: black;
		width: 26%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

	#player2 {
		width: 80% !important;
		height: 30% !important;
	}


	.theater-info-part {
		width: 95%;
	}
	.training-info-part {
		width: 95%;
	}
	.special-skills-info-part {
		width: 95%;
	}
	.theater-info-part-last {
		width: 95%;
	}
	.training-info-part-last {
		width: 95%;
	}
	.special-skills-info-part-last {
		width: 95%;
	}

	.theatre-container {
		width: 80%;
	}
	
	.tv-container {
		width: 80%;
	}
	
	.podcasts-container {
		width: 80%;
	}
	
	.training-container {
		width: 80%;
	}
	.special-skills-container {
		width: 80%;
		margin-bottom: 5%;
	}

	.homepage-img-of-josh {
		height: 280px;
		margin-bottom: 5%;
	}
	.home-page-main-container {
		margin-top: 5%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 315%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

	.images-tab-imgs-of-josh {
		margin-bottom: 15%;
	}
	#images-tab-imgs-of-josh-last {
		margin-bottom: 5%;
	}
}

@media screen and (max-width: 435px) {
  /* mobile */
	.about-title-text {
		width: 43%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}
	.headshots-header-text {
		width: 47%;

	}
	#label {
    display: block;
		cursor: pointer;
		margin-top: 5%;
	}
	.josh-name-big {
		color: #111;
		font-size: 47px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
		padding-top: 10%;
	}

	.media-tab-imgs-of-josh {
  	height: 200px;
  	margin: 0% 3% 10% 3%;
  	box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
  	transition: .3s ease-in-out;
	}

	.media-tab-imgs-of-josh-small {
  	height: 200px;
  	margin: 0% 3% 10% 3%;
  	box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
  	transition: .3s ease-in-out;
	}

	.reel-content-container-div {
		height: 400px;
		margin-top: 5%;
	}
	#player2 {
		width: 80% !important;
		height: 40% !important;
	}

	.josh-name-big {
		color: #111;
		font-size: 38px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
	}

	#player {
		width: 80% !important;
		height: 55% !important;
	}
	.homepage-alternate-background-color {
		background-image: url('https://i.imgur.com/AH09luV.png');
		height: 340px;
		width: 85%;
		margin: 0 auto;
		margin-top: 10%;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-direction: column;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 315%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
	
}

@media screen and (max-width: 400px) {
  /* mobile */
	.about-title-text {
		width: 47%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

	.headshots-header-text {
		width: 55%;

	}

		
	.media-header-text {
		color: black;
		width: 29%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

	.theater-info-part {
		width: 95%;
	}
	.training-info-part {
		width: 95%;
	}
	.special-skills-info-part {
		width: 95%;
	}
	.theater-info-part-last {
		width: 95%;
	}
	.training-info-part-last {
		width: 95%;
	}
	.special-skills-info-part-last {
		width: 95%;
	}

	.theatre-container {
		width: 85%;
	}
	.tv-container {
		width: 85%;
	}
	.podcasts-container {
		width: 85%;
	}
	.training-container {
		width: 85%;
	}
	.special-skills-container {
		width: 85%;
		margin-bottom: 5%;
	}

	.homepage-img-of-josh {
		height: 250px;
		margin-bottom: 5%;
	}
	.home-page-main-container {
		margin-top: 5%;
	}

	#label {
    display: block;
		cursor: pointer;
		margin-top: 10%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 415%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

	.images-tab-imgs-of-josh:hover {
		transform: scale(1.3);
	}
}


@media screen and (max-width: 380px) {
  /* mobile */
	.social-container {
		width: 300px;
		margin: 0 auto;
		text-align: center;
		padding-top: 1%;
	}
	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 415%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
}

@media screen and (max-width: 370px) {
  /* mobile */
	.about-title-text {
		width: 52%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}
	.headshots-header-text {
		width: 60%;

	}

	.media-tab-imgs-of-josh {
		height: 150px;
		margin: 0% 3% 10% 3%;
		box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
		transition: .3s ease-in-out;
	}
	
	.media-tab-imgs-of-josh-small {
		height: 150px;
		margin: 0% 3% 10% 3%;
		box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
		transition: .3s ease-in-out;
	}
		
	.media-header-text {
		color: black;
		width: 31%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

	#player2 {
		width: 80% !important;
		height: 25% !important;
	}

	#player {
		width: 80% !important;
		height: 45% !important;
	}
	.homepage-alternate-background-color {
		background-image: url('https://i.imgur.com/AH09luV.png');
		height: 340px;
		width: 85%;
		margin: 0 auto;
		margin-top: 10%;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-direction: column;
	}

	.social-container {
		width: 300px;
		margin: 0 auto;
		text-align: center;
		padding-top: 1%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 46.15%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
}

@media screen and (max-width: 326px) {
  /* mobile */
	.about-title-text {
		width: 54%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

	.headshots-header-text {
		width: 62%;

	}

	.social-container {
		width: 300px;
		margin: 0 auto;
		text-align: center;
		padding-top: 1%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 515%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

}


@media screen and (max-width: 296px) {
	/* mobile */
	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 515%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

}


@media screen and (max-width: 284px) {
	/* mobile */
	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 515%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

}
