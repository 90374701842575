/* ––––––––– Media Queries –––––––– */

@media only screen  and (min-width : 1800px) {
	.nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 7%;
	}  

  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 7%;
	}
	
  .nav-li-item {
    list-style: none;
    margin-left: 14%;
    margin-right: 14%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 13%;
  	margin-right: 14%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 14%;
  	margin-right: 12%;
	}


	.homepage-alternate-background-color {
		background-image: url('https://i.imgur.com/AH09luV.png');
		height: 550px;
		width: 55%;
		margin: 0 auto;
		margin-top: 10%;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-direction: column;
	}
	
	.home-page-main-container {
		height: 1400px;
		width: 100%;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
	}
	
	.homepage-img-of-josh {
		height: 600px;
		border-radius: 4px;
		-webkit-box-shadow: 6px 6px 52px -1px rgb(113,209,189);
		-moz-box-shadow: 6px 6px 52px -1px rgb(113,209,189);
		box-shadow: 6px 6px 52px -1px rgb(113,209,189);
		z-index: 4;
		margin-bottom: .5%;
	}

	.nav-bullets-div {
		display: flex;
		justify-content: center;
		height: 170px;
	}

	.about-title-text {
		width: 11%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}
	
	.headshots-header-text {
		width: 10%;
	}

	.images-content-container {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		width: 70%;
	}

	.media-content-container {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		width: 80%;
		padding-top: 8%;
		margin-bottom: 10%;
		margin: 0 auto;
	}

	.media-header-text {
		color: #70d1bd;
		width: 8%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}
	
	.reel-content-container-div {
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-direction: column;
		background-image: url('https://i.imgur.com/AH09luV.png');
		height: 540px;
		width: 70%;
		margin: 0 auto;
		border-radius: 5px;
		margin-top: 1%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 15%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
	
}

@media only screen  and (min-width : 1665px) {
	
	.nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 7%;
	}  
    
  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 7%;
	}
  .nav-li-item {
    list-style: none;
    margin-left: 14%;
    margin-right: 14%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 13%;
  	margin-right: 14%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 14%;
  	margin-right: 12%;
	}

	.about-title-text {
		width: 12%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

	.headshots-header-text {
		width: 12%;
	}
	.images-content-container {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		width: 70%;
	}

	.media-header-text {
		color: black;
		width: 8%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}
		
	.reel-content-container-div {
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-direction: column;
		background-image: url('https://i.imgur.com/AH09luV.png');
		height: 540px;
		width: 70%;
		margin: 0 auto;
		border-radius: 5px;
		margin-top: 1%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 115%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
	
	
}


@media screen and (max-width: 1655px) {

  .nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
	}  
    
  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
	}
  .nav-li-item {
    list-style: none;
    margin-left: 12%;
    margin-right: 12%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 11%;
  	margin-right: 12%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 12%;
  	margin-right: 10%;
	}

	.about-title-text {
		width: 14%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}
	.headshots-header-text {
		width: 12%;
	}
	.images-content-container {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		width: 90%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 115%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

	
}

@media screen and (max-width: 1620px) {
  .nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
	}  
    
  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
	}
  .nav-li-item {
    list-style: none;
    margin-left: 12%;
    margin-right: 12%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 11%;
  	margin-right: 12%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 12%;
  	margin-right: 10%;
	}

	.headshots-header-text {
		width: 12%;
	}

		
	.media-header-text {
		color: black;
		width: 8%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

}

@media screen and (max-width: 1595px) {

	.nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
	}  
    
  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
	}
  .nav-li-item {
    list-style: none;
    margin-left: 12%;
    margin-right: 12%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 11%;
  	margin-right: 12%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 12%;
  	margin-right: 10%;
	}

	.about-title-text {
		width: 20%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

	.headshots-header-text {
		width: 13%;
	}
}

@media screen and (max-width: 1555px) {

  .nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
	}  
    
  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
	}
  .nav-li-item {
    list-style: none;
    margin-left: 12%;
    margin-right: 12%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 11%;
  	margin-right: 12%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 12%;
  	margin-right: 10%;
	}
	.headshots-header-text {
		width: 13%;
	}

}

@media screen and (max-width: 1540px) {

  .nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
	}  
    
  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
	}
  .nav-li-item {
    list-style: none;
    margin-left: 12%;
    margin-right: 12%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 11%;
  	margin-right: 12%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 12%;
  	margin-right: 10%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 115%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

}

@media screen and (max-width: 1478px) {

	.nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
	}  
    
  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
	}
  .nav-li-item {
    list-style: none;
    margin-left: 12%;
    margin-right: 12%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 11%;
  	margin-right: 12%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 12%;
  	margin-right: 10%;
	}

		
	.media-header-text {
		color: black;
		width: 9%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

}

@media screen and (max-width: 1444px) {

  .nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
	}  
    
  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
	}
  .nav-li-item {
    list-style: none;
    margin-left: 10%;
    margin-right: 10%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 9%;
  	margin-right: 10%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 10%;
  	margin-right: 8%;
	}

	.headshots-header-text {
		width: 14%;
	}
}



@media screen and (max-width: 1421px) {

  .nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
  }
    
  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
  }
    
  .nav-li-item {
    list-style: none;
    margin-left: 10%;
    margin-right: 10%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 9%;
  	margin-right: 10%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 10%;
  	margin-right: 8%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 115%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

}


@media screen and (max-width: 1375px) {

  .nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
	}

  .navl-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
	} 

  .nav-li-item {
    list-style: none;
    margin-left: 10%;
    margin-right: 10%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 9%;
  	margin-right: 10%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 10%;
  	margin-right: 8%;
	}

	.about-title-text {
		width: 20%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

}




@media screen and (max-width: 1330px) {

  .nav-ul-container-left {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-left: 5%;
  }

  .nav-ul-container-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 25%;
    margin-right: 5%;
  }

  .nav-li-item {
    list-style: none;
    margin-left: 8%;
    margin-right: 8%;
	}
	
	.nav-li-item-reel {
  	list-style: none;
  	margin-left: 7%;
  	margin-right: 8%;
	}

	.nav-li-item-media {
  	list-style: none;
  	margin-left: 8%;
  	margin-right: 6%;
	}
	.headshots-header-text {
		width: 15%;
	}

	.media-header-text {
		color: black;
		width: 10%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}
}


@media screen and (max-width: 1266px) {

	#nav-li-link-title {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-content: center;
		align-items: center;
		text-decoration: none;
		width: 100%;
	}

	.joshs-name-div {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-content: center;
		align-items: center;
		width: 35%;
		margin-right: 5%;
		margin-left: 5%;
	}

	.josh-name-big {
		color: #111;
		font-size: 45px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
	}
	.joshs-name-div {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-content: center;
		align-items: center;
		width: 45%;
		margin-right: 5%;
		margin-left: 5%;
	}
	.headshots-header-text {
		width: 15%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 115%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

}


@media screen and (max-width: 1248px) {
	.josh-name-big {
		color: #111;
		font-size: 43px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
	}
	.joshs-name-div {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-content: center;
		align-items: center;
		width: 45%;
		margin-right: 5%;
		margin-left: 5%;
	}

	.about-title-text {
		width: 20%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}
	.headshots-header-text {
		width: 16%;
	}

	.reel-title {
		font-family: 'Cutive Mono', monospace;
		font-weight: 200;
		font-size: 32px;
		border-bottom: 1px solid black;
		margin: 0 auto;
		margin-bottom: 6%; 
	}
}


@media screen and (max-width: 1200px) {
  /* Large Desktop */
	.josh-name-big {
		color: #111;
		font-size: 43px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
	}
	.joshs-name-div {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-content: center;
		align-items: center;
		width: 45%;
		margin-right: 5%;
		margin-left: 5%;
	}
	
	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 115%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
}

@media screen and (max-width: 1155px) {
  /* Large Desktop */
	.josh-name-big {
		color: #111;
		font-size: 40px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
	}
	.joshs-name-div {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-content: center;
		align-items: center;
		width: 45%;
		margin-right: 5%;
		margin-left: 5%;
	}
	.about-title-text {
		width: 20%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

	.about-title-text {
		width: 20%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}
	.headshots-header-text {
		width: 17%;
	}
		
	.media-header-text {
		color: black;
		width: 11%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

	.theater-info-part {
		width: 45%;
	}
	.training-info-part {
		width: 45%;
	}
	.special-skills-info-part {
		width: 45%;
	}
	.theater-info-part-last {
		width: 45%;
	}
	.training-info-part-last {
		width: 45%;
	}
	.special-skills-info-part-last {
		width: 45%;
	}


	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 14.15%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

}


@media screen and (max-width: 1043px) {
  /* Large Desktop */
	.josh-name-big {
		color: #111;
		font-size: 43px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
	}
	.joshs-name-div {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-content: center;
		align-items: center;
		width: 45%;
		margin-right: 5%;
		margin-left: 5%;
	}

	.about-title-text {
		width: 20%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

	.headshots-header-text {
		width: 19%;
	}
		
	.media-header-text {
		color: black;
		width: 12%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

	.contact-form-container {
		width: 40%;
		margin: 0 auto;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 115%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
}

@media screen and (max-width: 975px) {
  /* Large Desktop */
	.homepage-img-of-josh {
		height: 400px;
		border-radius: 4px;
		/* padding-right: 20%; */
		-webkit-box-shadow: 6px 6px 52px -1px rgb(113,209,189);
		-moz-box-shadow: 6px 6px 52px -1px rgb(113,209,189);
		box-shadow: 6px 6px 52px -1px rgb(113,209,189);
		z-index: 4;
		margin-bottom: .5%;
	}


	.theater-info-part {
		width: 50%;
	}
	.training-info-part {
		width: 50%;
	}
	.special-skills-info-part {
		width: 50%;
	}
	.theater-info-part-last {
		width: 50%;
	}
	.training-info-part-last {
		width: 50%;
	}
	.special-skills-info-part-last {
		width: 50%;
	}


	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 115%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
}

@media screen and (max-width: 954px) {
	.josh-name-big {
		color: #111;
		font-size: 42px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
	}
	.joshs-name-div {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-content: center;
		align-items: center;
		width: 100%;
		margin: 0;
	}
	.nav-bullets-div {
		display: flex;
		justify-content: center;
		height: 50px;
		margin-top: 5%;
	}
	#arrow-link {
		text-decoration: none;
		display: flex;
		justify-content: center;
		margin-top: 2%;
	}

	.about-text-container {
		margin-top: 2%;
		width: 70%;
	}
	.headshots-header-text {
		width: 21%;
	}
		
	.media-header-text {
		color: black;
		width: 14%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

	.home-page-main-container {
		margin-top: 2%;
	}


	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 115%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
}

@media screen and (max-width: 880px) {
	
	#player {
		width: 80% !important;
		height: 61% !important;
	}
	#player2 {
		width: 80% !important;
		height: 61% !important;
	}


	.about-title-text {
		width: 22%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}
	
	.headshots-header-text {
		width: 22%;
	}

	.reel-content-container-div {
		height: 480px;
		margin-top: 5%;
	}

	.theater-info-part {
		width: 60%;
	}
	.training-info-part {
		width: 60%;
	}
	.special-skills-info-part {
		width: 60%;
	}
	.theater-info-part-last {
		width: 60%;
	}
	.training-info-part-last {
		width: 60%;
	}
	.special-skills-info-part-last {
		width: 60%;
	}


	.contact-form-container {
		width: 50%;
		margin: 0 auto;
	}


	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 215%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

	.contact-description {
		color: black;
		font-family: 'Cutive Mono', monospace;
		font-size: 20px;
		text-align: center;
		margin: 0 auto;
		margin-bottom: 4%;
		width: 90%;
	}
}


@media screen and (max-width: 786px) {
  /* tablet */
	.homepage-img-of-josh {
		height: 350px;
		border-radius: 4px;
		/* padding-right: 20%; */
		-webkit-box-shadow: 6px 6px 52px -1px rgb(113,209,189);
		-moz-box-shadow: 6px 6px 52px -1px rgb(113,209,189);
		box-shadow: 6px 6px 52px -1px rgb(113,209,189);
		z-index: 4;
		margin-bottom: .5%;
	}
	.about-title-text {
		width: 24%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}
	.about-text-container {
		margin-top: 2%;
		width: 75%;
	}

	.headshots-header-text {
		width: 24%;
	}

		
	.media-header-text {
		color: black;
		width: 16%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

	#player2 {
		width: 80% !important;
		height: 50% !important;
	}

	.reel-content-container-div {
		height: 480px;
		margin-top: 5%;
	}

	.credits-vitals-li {
		margin-left: 3%;
		margin-right: 3%;
		font-family: 'Cutive Mono', monospace;
		margin-top: 2%
	}

	.video-text {
		margin-bottom: 7%;
	}
	

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 215%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

	.images-tab-imgs-of-josh:hover {
		transform: scale(1.5);
	}

}


@media screen and (max-width: 747px) {
	#scroll-your-role-id {
		background-color: #71d1bd;
		font-family: 'Cutive Mono', monospace;
		border-radius: 100%;
		width: 50px;
		padding: 2px 13px 2px 0px;
		font-size: 20px;
		font-weight: 200;
		border: 1px solid black;
	}
}

@media screen and (max-width: 725px) {
	.members-equity-logo {
  	height: 25px;
		padding-left: 0%;
		margin-top: 2%;
		margin-bottom: 10%;
	}

	.theater-info-part {
		width: 70%;
	}
	.training-info-part {
		width: 70%;
	}
	.special-skills-info-part {
		width: 70%;
	}
	.theater-info-part-last {
		width: 70%;
	}
	.training-info-part-last {
		width: 70%;
	}
	.special-skills-info-part-last {
		width: 70%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 215%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
}


@media screen and (max-width: 709px) {
	.homepage-img-of-josh {
		height: 320px;
		border-radius: 4px;
		-webkit-box-shadow: 6px 6px 52px -1px rgb(113,209,189);
		-moz-box-shadow: 6px 6px 52px -1px rgb(113,209,189);
		box-shadow: 6px 6px 52px -1px rgb(113,209,189);
		z-index: 4;
		margin-bottom: .5%;
	}

		.about-title-text {
		width: 26%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

	.headshots-header-text {
		width: 28%;
	}

	.josh-name-big {
		color: #111;
		font-size: 40px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
	}
}

@media screen and (max-width: 667px) {
  /* mobile */
	.about-title-text {
		width: 31%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

	.headshots-header-text {
		width: 31%;
		margin-bottom: 10%;
	}

	.media-header-text {
		color: black;
		width: 19%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

	#player2 {
		width: 80% !important;
		height: 40% !important;
	}

	#player {
		width: 80% !important;
		height: 60% !important;
	}

	.member-equity-text {
		font-family: 'Cutive Mono', monospace;
		font-size: 15px;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-flow: row wrap;
		width: 50%;
		margin-bottom: 5%;
		text-align: center;
		margin-top: 4%;
	}


	.theater-info-part {
		width: 83%;
	}
	.training-info-part {
		width: 83%;
	}
	.special-skills-info-part {
		width: 83%;
	}
	.theater-info-part-last {
		width: 83%;
	}
	.training-info-part-last {
		width: 83%;
	}
	.special-skills-info-part-last {
		width: 83%;
	}

	.homepage-img-of-josh {
		height: 280px;
	}

	.home-page-main-container {
		margin-top: 3%;
	}

	.homepage-alternate-background-color {
		background-image: url('https://i.imgur.com/AH09luV.png');
		height: 400px;
		width: 80%;
		margin: 0 auto;
		margin-top: 10%;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-direction: column;
	}

	.contact-form-container {
		width: 59%;
		margin: 0 auto;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 215%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
}

@media screen and (max-width: 535px) {
  /* mobile */
	.about-title-text {
		width: 34%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

	.headshots-header-text {
		width: 38%;
	}

	.media-tab-imgs-of-josh {
		height: 230px;
		margin: 0% 3% 10% 3%;
		-webkit-box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
		box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
		transition: .3s ease-in-out;
	}
	
	.media-tab-imgs-of-josh-small {
		height: 250px;
		margin: 0% 3% 10% 3%;
		-webkit-box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
		box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
		transition: .3s ease-in-out;
	}
	
	.media-header-text {
		color: black;
		width: 23%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

	.theater-info-part {
		width: 95%;
	}
	.training-info-part {
		width: 95%;
	}
	.special-skills-info-part {
		width: 95%;
	}
	.theater-info-part-last {
		width: 95%;
	}
	.training-info-part-last {
		width: 95%;
	}
	.special-skills-info-part-last {
		width: 95%;
	}

	.homepage-img-of-josh {
		height: 280px;
		margin-bottom: 5%;
	}

	.josh-name-big {
		color: #111;
		font-size: 38px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
	}

	.home-page-content-container {
		width: 100%;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-direction: column;
		background-image: none;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 47% 50%;
		background-size: 100% 100%;
		padding-bottom: 4%;
	}

	#player {
		width: 80% !important;
		height: 60% !important;
	}
	.homepage-alternate-background-color {
		background-image: url('https://i.imgur.com/AH09luV.png');
		height: 370px;
		width: 85%;
		margin: 0 auto;
		margin-top: 10%;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-direction: column;
	}

	.contact-form-container {
		width: 63%;
		margin: 0 auto;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 315%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}


}

@media screen and (max-width: 480px) {
  /* mobile */
	.about-title-text {
		font-family: 'Cutive Mono', monospace;
		width: 39%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}
	.about-me-text {
		border-left: 2px solid lightgray;
		border-right: 2px solid lightgray;
		padding-left: 5%;
		padding-right: 5%;
	}
	.about-text-container {
		margin-top: 2%;
		width: 80%;
	}

	.headshots-header-text {
		width: 42%;
	}

	.media-header-text {
		color: black;
		width: 26%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

	#player2 {
		width: 80% !important;
		height: 30% !important;
	}


	.theater-info-part {
		width: 95%;
	}
	.training-info-part {
		width: 95%;
	}
	.special-skills-info-part {
		width: 95%;
	}
	.theater-info-part-last {
		width: 95%;
	}
	.training-info-part-last {
		width: 95%;
	}
	.special-skills-info-part-last {
		width: 95%;
	}

	.theatre-container {
		width: 80%;
	}
	
	.tv-container {
		width: 80%;
	}
	
	.podcasts-container {
		width: 80%;
	}
	
	.training-container {
		width: 80%;
	}
	.special-skills-container {
		width: 80%;
		margin-bottom: 5%;
	}

	.homepage-img-of-josh {
		height: 280px;
		margin-bottom: 5%;
	}
	.home-page-main-container {
		margin-top: 5%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 315%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

	.images-tab-imgs-of-josh {
		margin-bottom: 15%;
	}
	#images-tab-imgs-of-josh-last {
		margin-bottom: 5%;
	}
}

@media screen and (max-width: 435px) {
  /* mobile */
	.about-title-text {
		width: 43%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}
	.headshots-header-text {
		width: 47%;

	}
	#label {
    display: block;
		cursor: pointer;
		margin-top: 5%;
	}
	.josh-name-big {
		color: #111;
		font-size: 47px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
		padding-top: 10%;
	}

	.media-tab-imgs-of-josh {
  	height: 200px;
  	margin: 0% 3% 10% 3%;
  	-webkit-box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
  	-moz-box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
  	box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
  	transition: .3s ease-in-out;
	}

	.media-tab-imgs-of-josh-small {
  	height: 200px;
  	margin: 0% 3% 10% 3%;
  	-webkit-box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
  	-moz-box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
  	box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
  	transition: .3s ease-in-out;
	}

	.reel-content-container-div {
		height: 400px;
		margin-top: 5%;
	}
	#player2 {
		width: 80% !important;
		height: 40% !important;
	}

	.josh-name-big {
		color: #111;
		font-size: 38px;
		font-family: 'Rubik Mono One', sans-serif;
		font-weight: 500;
		text-shadow: 4px 4px 9px rgba(117, 65, 214, .4);
		text-align: center;
		text-transform: uppercase;
		display: inline-block;
	}

	#player {
		width: 80% !important;
		height: 55% !important;
	}
	.homepage-alternate-background-color {
		background-image: url('https://i.imgur.com/AH09luV.png');
		height: 340px;
		width: 85%;
		margin: 0 auto;
		margin-top: 10%;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-direction: column;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 315%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
	
}

@media screen and (max-width: 400px) {
  /* mobile */
	.about-title-text {
		width: 47%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

	.headshots-header-text {
		width: 55%;

	}

		
	.media-header-text {
		color: black;
		width: 29%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

	.theater-info-part {
		width: 95%;
	}
	.training-info-part {
		width: 95%;
	}
	.special-skills-info-part {
		width: 95%;
	}
	.theater-info-part-last {
		width: 95%;
	}
	.training-info-part-last {
		width: 95%;
	}
	.special-skills-info-part-last {
		width: 95%;
	}

	.theatre-container {
		width: 85%;
	}
	.tv-container {
		width: 85%;
	}
	.podcasts-container {
		width: 85%;
	}
	.training-container {
		width: 85%;
	}
	.special-skills-container {
		width: 85%;
		margin-bottom: 5%;
	}

	.homepage-img-of-josh {
		height: 250px;
		margin-bottom: 5%;
	}
	.home-page-main-container {
		margin-top: 5%;
	}

	#label {
    display: block;
		cursor: pointer;
		margin-top: 10%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 415%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

	.images-tab-imgs-of-josh:hover {
		transform: scale(1.3);
	}
}


@media screen and (max-width: 380px) {
  /* mobile */
	.social-container {
		width: 300px;
		margin: 0 auto;
		text-align: center;
		padding-top: 1%;
	}
	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 415%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
}

@media screen and (max-width: 370px) {
  /* mobile */
	.about-title-text {
		width: 52%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}
	.headshots-header-text {
		width: 60%;

	}

	.media-tab-imgs-of-josh {
		height: 150px;
		margin: 0% 3% 10% 3%;
		-webkit-box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
		box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
		transition: .3s ease-in-out;
	}
	
	.media-tab-imgs-of-josh-small {
		height: 150px;
		margin: 0% 3% 10% 3%;
		-webkit-box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
		box-shadow: 0px 2px 31px -3px rgba(0,0,0,0.75);
		transition: .3s ease-in-out;
	}
		
	.media-header-text {
		color: black;
		width: 31%;
		margin: 0 auto;
		padding-top: 3%;
		text-align: center;
	}

	#player2 {
		width: 80% !important;
		height: 25% !important;
	}

	#player {
		width: 80% !important;
		height: 45% !important;
	}
	.homepage-alternate-background-color {
		background-image: url('https://i.imgur.com/AH09luV.png');
		height: 340px;
		width: 85%;
		margin: 0 auto;
		margin-top: 10%;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-direction: column;
	}

	.social-container {
		width: 300px;
		margin: 0 auto;
		text-align: center;
		padding-top: 1%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 46.15%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}
}

@media screen and (max-width: 326px) {
  /* mobile */
	.about-title-text {
		width: 54%;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 6%;
		padding-top: 3%;
		display: flex;
		justify-content: center;
	}

	.headshots-header-text {
		width: 62%;

	}

	.social-container {
		width: 300px;
		margin: 0 auto;
		text-align: center;
		padding-top: 1%;
	}

	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 515%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

}


@media screen and (max-width: 296px) {
	/* mobile */
	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 515%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

}


@media screen and (max-width: 284px) {
	/* mobile */
	.contact-title {
	  color: #000000;
    font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		width: 515%;
		margin: 0 auto;
		text-align: center;
		font-weight: 800;
	}

}